const payments = [
  {
    text: "QR 3",
    enable: true,
    merchantId: 18,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 1200000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "QR 4",
    enable: true,
    merchantId: 19,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 2500000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 8",
    enable: true,
    merchantId: 20,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 9",
    enable: true,
    merchantId: 21,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "QR 11",
    enable: true,
    merchantId: 22,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 9",
    enable: true,
    merchantId: 23,
    type: "UPI",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 11",
    enable: true,
    merchantId: 24,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 12",
    enable: true,
    merchantId: 25,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 13",
    enable: true,
    merchantId: 26,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "UPI 15",
    enable: true,
    merchantId: 28,
    type: "BANKCARD",
    img: "",
    minDepositAmount: 5000,
    maxDepositAmount: 5000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 5000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
];

const wallets = [  
  {
    text: "rupeelink",
    enable: true,
    merchantId: 31,
    type: "UPI",
    img: "../../assets/images/home/rupeelink.png",
    minDepositAmount: 5000,
    maxDepositAmount: 10000000,
    minWithdrawAmount: 10000,
    maxWithdrawAmount: 10000000,
    checkPrecedence: (phone) => {
      return false;
    },
  },
];

export default {
  payments: payments.filter((payment) => payment.enable),
  wallets: wallets.filter((wallet) => wallet.enable),
  withdraw_type: "UPI", // BANKCARD, MOBILE_MONEY
};
