/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
  locationKey: "IN",
  currencyCode: "INR",
  currencyName: "Indian rupee",
  gameName: "SuWin8",
  appVersion: "1.1.0",
  forceUpdateToVersion: "1.0.8",
  companyName: "SUWIN8",
  licence: "No.0000357",
  availableCountryList: ["IN"],
  icon: require("@/assets/icon_in.png").default,
  appIcon: require("@/assets/app_icon_in.png").default,
  appIcon64: require("@/assets/app_icon_64x64_in.png").default,
  appIconRound: require("@/assets/icon_round_in.png").default,
  appIconRound64: require("@/assets/icon_round_64x64_in.png").default,
  titleImg: require("@/assets/images/header/fz_char_logo_in.png").default,
  titleImgWhite: require("@/assets/images/header/fz_char_logo_white_in.png").default,
  indexBannerHarvestImg: require("@/assets/images/home/index_banner_harvest_in.png").default,
  indexBannerDepositImg: "index_banner_deposit_in",
  indexBannerDeposit2Img: "index_banner_deposit2_in",
  indexBannerDrawImg: "index_banner_draw",
  indexBannerinviteImg: require("@/assets/images/home/index_banner_invite_in.png").default,
  indexBannerLuckyWheelImg: "index_banner_lucky_wheel_in",
  indexBannerInstallImg: "index_banner_install_in",
  indexBannerLeaderImg: "index_banner_leader_in",
  downloadTipTextImg: "top_zi_in",
  referFriendsPopup: require("@/assets/images/home/ing_2_in.png").default,
  registerDownloadAd: "reg_download_in",
  downloadFloatImg: require("@/assets/images/download/float-download-in.png").default,
  luckyWheelShareImg: "/assets/images/lucky_wheel/banner_x2_in.png",
};
